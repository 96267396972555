<template>
  <div>
    <label v-if="label" class="block text-sm font-medium text-gray-700">
      <T :keyName="label" />
    </label>
    <div :class="outerClass">
      <input
        type="text"
        :placeholder="placeholder"
        class="block w-full border-gray-300 pr-12 focus:border-primary-500 focus:ring-primary-500 text-sm"
        :value="inputValue"
        @change="inputChanged"
      />
      <div class="absolute inset-y-0 right-0 flex items-center">
        <label for="options" class="sr-only">Unit</label>
        <select
          :value="selectValue"
          @change="selectChanged"
          id="options"
          name="options"
          class="h-full border-transparent bg-transparent py-0 pl-2 pr-6 text-gray-500 focus:border-primary-500 focus:ring-primary-500 text-sm"
        >
          <option v-for="[key, value] in options.entries()" :value="key">
            {{ value.text }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";

const props = defineProps([
  "label",
  "inputValue",
  "selectValue",
  "options",
  "callback",
  "placeholder",
]);
const emits = defineEmits(["input-changed", "select-changed"]);

const inputChanged = (event) => {
  emits("input-changed", event.target.value);
};
const selectChanged = (event) => {
  emits("select-changed", event.target.value);
};

const outerClass = computed(() => {
  return `${props.label ? "mt-1" : ""} relative rounded-md shadow-sm`;
});
</script>
