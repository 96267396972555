<template>
  <label
    v-if="props.label"
    class="text-sm font-medium text-gray-700 dark:text-white"
    :class="props.labelClass"
  >
    <T :keyName="props.label" />
  </label>
  <ul class="grid w-full gap-2 md:grid-cols-2 mt-1">
    <li v-for="option in props.options">
      <input
        type="radio"
        :name="props.name"
        v-model="props.modelValue"
        :value="option.value"
        class="hidden peer"
        required
      />
      <label
        @click="emitInput(option.value)"
        class="text-sm inline-flex items-center justify-between w-full p-2 text-gray-500 bg-white border border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-primary-500 peer-checked:border-primary-600 peer-checked:text-primary-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
      >
        <div class="block">
          <div class="w-full"><T :keyName="option.text" /></div>
        </div>
        <CheckIcon
          v-if="option.value == props.modelValue"
          class="h-4 w-4 text-primary-500"
          aria-hidden="true"
        />
      </label>
    </li>
  </ul>
</template>

<script setup>
import { CheckIcon } from "@heroicons/vue/20/solid";

const props = defineProps([
  "label",
  "options",
  "name",
  "modelValue",
  "labelClass",
]);
const emits = defineEmits(["update:modelValue"]);
const emitInput = (val) => {
  emits("update:modelValue", val);
};
</script>

<style scoped></style>
