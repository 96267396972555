<template>
  <NotificationGroup group="notifications" position="bottom">
    <div
      id="notifications-container"
      class="fixed inset-x-0 bottom-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none z-50"
    >
      <div class="w-full max-w-sm">
        <Notification
          v-slot="{ notifications }"
          enter="transform ease-out duration-300 transition"
          enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
          enter-to="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
          move="transition duration-500"
          move-delay="delay-300"
        >
          <div
            class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white shadow-md"
            v-for="notification in notifications"
            :key="notification.id"
          >
            <div
              class="flex items-center justify-center w-12 bg-primary"
              :class="{ 'bg-red': notification.type == 'error' }"
            >
              <svg
                v-if="notification.type == 'error'"
                class="w-6 h-6 text-white fill-current stroke-red"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="red"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <svg
                v-else
                class="w-6 h-6 text-white fill-current"
                viewBox="0 0 40 40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"
                />
              </svg>
            </div>

            <div class="px-4 py-2 -mx-3">
              <div class="mx-3">
                <span
                  class="font-semibold text-primary"
                  :class="{ 'text-red': notification.type == 'error' }"
                  >{{ notification.title }}</span
                >
                <p class="text-sm text-gray-600">
                  {{ notification.text }}
                </p>
              </div>
            </div>
          </div>
        </Notification>
      </div>
    </div>
  </NotificationGroup>
  <div
    class="min-h-full"
    :class="{
      'overflow-hidden pointer-events-none opacity-50':
        !globalStore.cookiesAccepted,
    }"
  >
    <Nav
      @user-preferences-click="
        state.userPreferencesOpen = !state.userPreferencesOpen
      "
    />
    <div class="py-24 pb-16 min-h-[75vh]">
      <main>
        <div class="relative mx-auto max-w-7xl sm:px-6 lg:px-8">
          <template v-if="state.loading">
            <div
              class="absolute top-0 bottom-0 left-0 right-0 backdrop-blur-1 z-50 h-[50vh]"
            >
              <div class="m-auto block w-fit relative top-[48%]">
                <svg
                  class="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-primary-500"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
            </div>
          </template>
          <template v-else>
            <RouterView />
          </template>
        </div>
      </main>
    </div>
    <Footer />
  </div>
  <template v-if="isAuthenticated">
    <UserPreferencesModal
      :open="state.userPreferencesOpen"
      @on-close="state.userPreferencesOpen = false"
    />
  </template>
  <Modal
    :open="state.showMaintenanceNotification"
    @on-close="state.showMaintenanceNotification = false"
    panel-class="!max-w-sm"
  >
    <template #content>
      <div class="flex justify-center">
        <ExclamationTriangleIcon class="h-16 w-16 text-amber-500" />
      </div>
      <div class="flex justify-center">
        The software will be offline from {{ state.startMaintenanceUtc }} UTC to
        {{ state.endMaintenanceUtc }} UTC for planned maintenance. Please ensure
        you have no unsaved changes as these will be lost.
      </div>
    </template>
  </Modal>
  <CookieConsent v-if="requireCookieConsent && !globalStore.cookiesAccepted" />
</template>

<script setup>
import Nav from "../components/layout/Nav.vue";
import Footer from "../components/layout/Footer.vue";
import { notify } from "notiwind";
import UserPreferencesModal from "../components/user/UserPreferencesModal.vue";
import { reactive, onBeforeMount, watch } from "vue";
import { useAuthStore } from "../stores/auth";
import { storeToRefs } from "pinia";
import { useProjectStore } from "../stores/project";
import { useSelectionStore } from "../stores/selection";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";
import { useGlobalStore } from "../stores/global";
import Modal from "../components/shared/Modal.vue";
import CookieConsent from "../components/layout/CookieConsent.vue";

const requireCookieConsent = true;

const projectStore = useProjectStore();
const authStore = useAuthStore();
const selectionStore = useSelectionStore();
const globalStore = useGlobalStore();
const { selectionInformation } = storeToRefs(selectionStore);
const { user, isAuthenticated } = storeToRefs(authStore);
const state = reactive({
  userPreferencesOpen: false,
  loading: true,
  showMaintenanceNotification: false,
  startMaintenanceUtc: "",
  endMaintenanceUtc: "",
});

const loadData = () => {
  if (isAuthenticated.value) {
    projectStore
      .getCurrentProject()
      .then(() => {
        if (
          selectionInformation.value.productRanges == null ||
          selectionInformation.value.databaseVersion !=
            globalStore.databaseVersion ||
          selectionInformation.value.softwareVersion !=
            globalStore.softwareVersion
        ) {
          selectionStore.getSelectionInformation(user.value.id).then((data) => {
            selectionStore.setSelectionInformation(data);
            state.loading = false;
          });
        } else {
          state.loading = false;
        }
      })
      .catch(() => {
        authStore.logout();
        window.location.reload();
      });
  } else {
    state.loading = false;
  }
};

onBeforeMount(() => {
  globalStore.getCookieConsent();
  globalStore.getVersion().then(() => {
    globalStore.checkMaintenance().then((response) => {
      if (response.showNotification) {
        state.endMaintenanceUtc = response.maintenanceEndUtc;
        state.startMaintenanceUtc = response.maintenanceStartUtc;
        state.showMaintenanceNotification = true;
      }
    });
    loadData();
  });

  watch(
    () => isAuthenticated.value,
    () => loadData()
  );
});
</script>
