<template>
  <div
    v-if="loading"
    class="absolute top-0 bottom-0 left-0 right-0 backdrop-blur-1 z-50"
  >
    <div class="m-auto block w-fit relative top-[48%]">
      <svg
        class="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-primary-500"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </div>
  </div>
  <nav class="flex mb-3 xs:px-2" aria-label="Breadcrumb">
    <ol
      role="list"
      class="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8 shadow bg-white"
    >
      <!-- <li class="flex">
        <div class="flex items-center">
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            <span class="sr-only">Home</span>
          </a>
        </div>
      </li> -->
      <li v-for="(page, idx) in pages" :key="page.name" class="flex">
        <div class="flex items-center">
          <svg
            v-if="idx > 0"
            class="h-full w-6 flex-shrink-0 text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <button
            @click="router.push(page.href)"
            class="text-sm font-medium text-gray-500 hover:text-gray-700"
            :class="{ 'ml-4': idx > 0 }"
            :aria-current="page.current ? 'page' : undefined"
          >
            {{ page.name }}
          </button>
        </div>
      </li>
    </ol>
  </nav>
  <div
    class="relative border-b border-gray-200 pb-5 sm:pb-0 mb-4 lg:max-xl:px-0 px-2"
  >
    <div class="md:flex md:items-center md:justify-between pt-3">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
        <!-- <button class="h-[24px] align-top" @click="previousResult">
          <ChevronLeftIcon class="block h-6 w-6" />
        </button> -->
        {{ state.selectionResult.model }}
        <!-- <button class="h-[24px] align-top" @click="nextResult">
          <ChevronRightIcon class="block h-6 w-6" />
        </button> -->
      </h3>
      <div
        v-if="globalStore.isMobile"
        class="fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600"
      >
        <div class="grid h-full max-w-lg grid-cols-2 mx-auto font-medium">
          <div
            v-if="user.userGroup.isAdmin"
            class="inline-flex flex-col items-center justify-center"
          >
            <Button
              button-color="primary"
              button-text="button_text_export_csv"
              button-size="xs"
              @click="exportCsvData"
            />
          </div>
          <div class="inline-flex flex-col items-center justify-center">
            <Button
              v-if="isProjectReferrer"
              button-color="primary"
              button-text="button_text_save_changes"
              button-size="xs"
              :class="saveChangesClass"
              @click="saveProjectSelection"
            />
            <Button
              v-else
              button-color="primary"
              button-text="button_text_add_to_project"
              button-size="xs"
              :class="addToProjectClass"
              @click="addToProject"
            />
          </div>
          <div class="inline-flex flex-col items-center justify-center">
            <Button
              v-if="!showDownloadButton"
              :disabled="state.isPrinting"
              button-color="primary"
              :button-text="printButtonText"
              button-size="xs"
              class="ml-3"
              @click="printProduct"
            >
              <template v-if="state.isPrinting" #pre-content>
                <svg
                  aria-hidden="true"
                  role="status"
                  class="inline w-4 h-4 mr-3 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              </template>
            </Button>

            <Button
              v-if="showDownloadButton"
              :use-as-anchor="true"
              :href="state.printDownloadUrl"
              download
              button-color="primary"
              button-text="button_text_download_pdf"
              button-size="xs"
              class="ml-3"
            >
            </Button>
          </div>
        </div>
      </div>
      <div
        v-if="!globalStore.isMobile"
        class="mt-3 flex md:absolute md:top-3 md:right-0 md:mt-0"
      >
        <Button
          v-if="user.userGroup.isAdmin"
          button-color="primary"
          button-text="button_text_export_csv"
          button-size="xs"
          class="mr-3"
          @click="exportCsvData"
        />
        <Button
          v-if="isProjectReferrer"
          button-color="primary"
          button-text="button_text_save_changes"
          button-size="xs"
          :class="saveChangesClass"
          @click="saveProjectSelection"
        />
        <Button
          v-else
          button-color="primary"
          button-text="button_text_add_to_project"
          button-size="xs"
          :class="addToProjectClass"
          @click="addToProject"
        />
        <Button
          v-if="!showDownloadButton"
          :disabled="state.isPrinting"
          button-color="primary"
          :button-text="printButtonText"
          button-size="xs"
          class="ml-3"
          @click="printProduct"
        >
          <template v-if="state.isPrinting" #pre-content>
            <svg
              aria-hidden="true"
              role="status"
              class="inline w-4 h-4 mr-3 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
          </template>
        </Button>

        <Button
          v-if="showDownloadButton"
          :use-as-anchor="true"
          :href="state.printDownloadUrl"
          download
          button-color="primary"
          button-text="button_text_download_pdf"
          button-size="xs"
          class="ml-3"
        >
        </Button>
      </div>
    </div>

    <div
      v-if="!globalStore.isMobile"
      class="mt-4 flex items-center justify-between"
    >
      <div class="min-w-max max-w-max pr-4">
        <img :src="getProductImage" class="mx-auto" />
      </div>
      <div class="w-full whitespace-pre-line">
        <div>
          <T :keyName="state.selectionResult.shortDescription" />
        </div>
        <div class="text-sm">
          <T :keyName="state.selectionResult.longDescription" />
        </div>
      </div>
    </div>

    <div v-if="globalStore.isMobile" class="mt-4 grid grid-cols-1">
      <div class="col-span-1">
        <img :src="getProductImage" class="mx-auto" />
      </div>

      <div class="col-span-1 whitespace-pre-line">
        <div>
          <T :keyName="state.selectionResult.shortDescription" />
        </div>
        <div class="text-sm">
          <T :keyName="state.selectionResult.longDescription" />
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div class="sm:hidden">
        <label for="current-tab" class="sr-only">Select a tab</label>
        <select
          id="current-tab"
          name="current-tab"
          class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
        >
          <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">
            {{ tab.name }}
          </option>
        </select>
      </div>
      <div class="hidden sm:block">
        <nav class="-mb-px flex space-x-8">
          <button
            v-for="tab in tabs"
            :key="tab.name"
            @click="setActiveTab(tab)"
            :class="[
              tab.current
                ? 'border-primary-500 text-primary-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm',
            ]"
            :aria-current="tab.current ? 'page' : undefined"
          >
            {{ tab.name }}
          </button>
        </nav>
      </div>
    </div>
  </div>
  <div v-if="activeTab == 0" class="grid md:grid-cols-3 sm:grid-cols-1 gap-4">
    <div class="md:col-span-2 sm:col-span-1">
      <div class="columns-1">
        <div class="divide-y divide-gray-200 overflow-hidden bg-white shadow">
          <div class="px-4 py-2 text-white bg-primary">
            <div class="text-md">
              <T keyName="card_title_performance_chart" />
            </div>
          </div>
          <div class="px-2 pt-2">
            <Chart :options="pressureChartOptions" />
          </div>
          <div class="px-2 py-0">
            <Chart :options="powerChartOptions" />
          </div>
          <div class="px-2 pb-2">
            <Chart :options="efficiencyChartOptions" />
          </div>
        </div>
      </div>
    </div>
    <div class="columns-1">
      <TechnicalSection
        v-if="state.technicalSections.selection"
        :loading="false"
        :section="state.technicalSections.selection"
      />
      <TechnicalSection
        class="mt-3"
        v-if="state.technicalSections.motor"
        :loading="false"
        :section="state.technicalSections.motor"
      />
      <div
        v-if="state.selectionResult.soundSpectrums.length > 0"
        class="divide-y divide-gray-200 overflow-hidden bg-white shadow mt-3"
      >
        <div class="px-4 py-2 bg-primary">
          <div class="text-md text-white">
            <T keyName="Sound" />
          </div>
        </div>
        <div class="px-4 py-2">
          <div class="grid grid-cols-1 gap-2">
            <div class="col-span-1">
              <table class="text-xs w-full text-center">
                <thead class="font-bold">
                  <tr>
                    <td></td>
                    <td>63</td>
                    <td>125</td>
                    <td>250</td>
                    <td>500</td>
                    <td>1k</td>
                    <td>2k</td>
                    <td>4k</td>
                    <td>8k</td>
                    <td>Overall</td>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="spectrum in state.selectionResult.soundSpectrums"
                  >
                    <tr class="border-t">
                      <td class="font-bold">
                        {{ spectrum.description }}
                      </td>
                      <td>{{ spectrum.db[0] }}</td>
                      <td>{{ spectrum.db[1] }}</td>
                      <td>{{ spectrum.db[2] }}</td>
                      <td>{{ spectrum.db[3] }}</td>
                      <td>{{ spectrum.db[4] }}</td>
                      <td>{{ spectrum.db[5] }}</td>
                      <td>{{ spectrum.db[6] }}</td>
                      <td>{{ spectrum.db[7] }}</td>
                      <td>{{ spectrum.overall }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="activeTab == 1" class="grid md:grid-cols-12 sm:grid-cols-1 gap-4">
    <template v-for="accessory in state.selectionResult.accessories">
      <template v-if="accessory.quantity > 0">
        <div class="md:col-span-2 sm:col-span-1">
          <div class="divide-y divide-gray-200 overflow-hidden bg-white shadow">
            <div class="px-2 py-2">
              <div class="grid grid-cols-12">
                <div class="col-span-12 mb-1">
                  <img
                    :src="getAccessoryImage(accessory)"
                    class="mx-auto max-h-32"
                    alt=""
                  />
                </div>
                <div class="col-span-12 mx-auto mb-1">
                  <label class="text-xs text-ellipsis">
                    {{ accessory.productText }}
                  </label>
                </div>
                <div class="col-span-12 mx-auto mb-1">
                  <a
                    v-if="accessory.drawingFilename"
                    class="text-xs hover:underline"
                    download
                    :href="getAccessoryDrawing(accessory)"
                    >Download Drawing</a
                  >
                  <label v-else class="text-xs text-gray-400">
                    Drawing unavailable
                  </label>
                </div>
                <!-- <div class="col-span-2 col-start-3">
                <Button
                  button-size="sm"
                  class="!py-1 !px-1 rounded-r-none w-full"
                  button-color="primary"
                  @click="decreaseAccessoryQuantity(accessory)"
                >
                  <template #content>
                    <MinusIcon
                      class="h-5 w-5 flex-shrink-0"
                      aria-hidden="true"
                    />
                  </template>
                </Button>
              </div> -->
                <div class="col-span-12 flex justify-center text-xs">
                  <span> Quantity: {{ accessory.quantity }} </span>
                </div>
                <div class="col-span-12 flex justify-center text-xs">
                  <span> Price: {{ accessory.price.toFixed(2) }} </span>
                </div>
                <!-- <div class="col-span-2">
                <Button
                  button-size="sm"
                  class="!py-1 !px-1 justify-end flex self-end rounded-l-none w-full"
                  button-color="primary"
                  @click="increaseAccessoryQuantity(accessory)"
                >
                  <template #content>
                    <PlusIcon
                      class="h-5 w-5 flex-shrink-0"
                      aria-hidden="true"
                    />
                  </template>
                </Button>
              </div> -->
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
  <div v-if="activeTab == 2" class="grid md:grid-cols-4 sm:grid-cols-1 gap-4">
    <div class="md:col-span-3 sm:col-span-1">
      <div class="columns-1">
        <div class="divide-y divide-gray-200 overflow-hidden bg-white shadow">
          <div class="px-4 py-2 bg-primary text-white">
            <div class="text-md" v-if="!loading">
              <T keyName="card_title_drawing" />
            </div>
            <div
              v-else
              role="status"
              class="space-y-2.5 animate-pulse max-w-lg py-[7px]"
            >
              <div class="flex items-center space-x-2 w-52">
                <div
                  class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"
                ></div>
              </div>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div class="px-2">
            <img :src="getDrawing" class="max-w-full mx-auto" />
            <span class="text-xs text-gray-400 italic"
              >Note: Dimensions are subject to change</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="columns-1">
      <div class="divide-y divide-gray-200 overflow-hidden bg-white shadow">
        <div class="px-4 py-2 bg-primary text-white">
          <div class="text-md" v-if="!loading">
            <T keyName="card_title_dimensions" />
          </div>
          <div
            v-else
            role="status"
            class="space-y-2.5 animate-pulse max-w-lg py-[7px]"
          >
            <div class="flex items-center space-x-2 w-52">
              <div
                class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"
              ></div>
            </div>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="px-4 py-2">
          <div v-if="!loading" class="grid grid-cols-2 gap-2">
            <div class="col-span-1"></div>
            <div class="col-span-1">
              <label v-if="!loading" class="text-xs font-semibold">mm</label>
            </div>
          </div>
          <div
            v-if="!loading"
            v-for="item in state.selectionResult.drawingDimensions.dimensions"
            class="grid grid-cols-2 gap-2"
          >
            <div class="col-span-1">
              <label v-if="!loading" class="text-xs"
                ><T :keyName="item.key"
              /></label>
            </div>
            <div class="col-span-1">
              <label v-if="!loading" class="text-xs"
                ><T :keyName="item.value"
              /></label>
            </div>
          </div>
          <div v-else class="grid grid-cols-2 gap-2">
            <template v-for="i in 4">
              <div class="columns-1">
                <div
                  role="status"
                  class="space-y-2.5 animate-pulse max-w-lg py-[7px]"
                >
                  <div class="flex items-center space-x-2 w-40">
                    <div
                      class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"
                    ></div>
                  </div>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="columns-1">
                <div
                  role="status"
                  class="space-y-2.5 animate-pulse max-w-lg py-[7px]"
                >
                  <div class="flex items-center space-x-2 w-40">
                    <div
                      class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"
                    ></div>
                  </div>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-show="activeTab == 3"
    class="grid grid-cols-12 xs:grid-cols-1 gap-4 xs:px-2"
  >
    <div
      v-for="asset in state.selectionResult.productAssets"
      class="md:col-span-2 sm:col-span-1"
    >
      <div
        class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow"
      >
        <div class="px-2 py-2">
          <div class="grid grid-cols-12">
            <div class="col-span-12 mb-1">
              <DocumentArrowDownIcon class="w-24 h-24 mx-auto text-gray-300" />
            </div>
            <div class="col-span-12 mx-auto mb-1">
              <label class="text-xs text-ellipsis">
                {{ asset.description }}
              </label>
            </div>
            <div class="col-span-12 mx-auto mb-1">
              <Button
                :use-as-anchor="true"
                :href="asset.downloadUrl"
                download
                button-color="primary"
                button-text="button_text_download"
                button-size="xs"
                class=""
              >
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useSelectionStore } from "../stores/selection";
import { useProjectStore } from "../stores/project";
import { useAuthStore } from "../stores/auth";
import { storeToRefs } from "pinia";
import { reactive, computed, watch } from "vue";
import { useRoute } from "vue-router";
import Button from "../components/shared/Button.vue";
import { Chart } from "highcharts-vue";
import { useRouter } from "vue-router";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
  MinusIcon,
  DocumentArrowDownIcon,
} from "@heroicons/vue/20/solid";
import { HomeIcon } from "@heroicons/vue/20/solid";
import TechnicalSection from "@/components/technical/TechnicalSection.vue";
import { notify } from "notiwind";
import Input from "@/components/shared/Input.vue";
import { useGlobalStore } from "../stores/global";

const router = useRouter();
const selectionStore = useSelectionStore();
const projectStore = useProjectStore();
const route = useRoute();
const globalStore = useGlobalStore();

const { user } = storeToRefs(useAuthStore());

const {
  volumeFlowUnits,
  pressureUnits,
  selectionInformation,
  selectionResultItems,
} = storeToRefs(selectionStore);

const { project } = storeToRefs(projectStore);

const state = reactive({
  tabs: [
    { id: 0, name: "Performance", href: "#", current: true },
    { id: 1, name: "Accessories", href: "#", current: false },
    { id: 2, name: "Dimensional Drawing", href: "#", current: false },
    { id: 3, name: "Assets", href: "#", current: false },
  ],
  technicalSections: {},
  printDownloadUrl: "",
  isPrinting: false,
  loading: false,
  addToProjectBusy: false,
  selectionResult: null,
  replaceId: "",
});

const tabs = computed(() => {
  let tabs = [...state.tabs];
  if (state.selectionResult) {
    if (
      !state.selectionResult.productAssets ||
      state.selectionResult.productAssets.length == 0
    ) {
      tabs = tabs.filter((t) => t.name != "Assets");
    }
    if (
      state.selectionResult.accessories.length == 0 ||
      state.selectionResult.accessories.filter((a) => a.quantity > 0).length ==
        0
    ) {
      tabs = tabs.filter((t) => t.name != "Accessories");
    }
  }
  return tabs;
});

const isProjectReferrer = computed(() => {
  return route.params.referrer == "project";
});

const addToProjectClass = computed(() => {
  return state.addToProjectBusy ? "opacity-75 pointer-events-none" : "";
});

const getAccessoryDrawing = (accessory) => {
  return accessory.drawingFilename && accessory.drawingFilename.length
    ? `${window.location.origin}/assets/images/drawings/accessories/${accessory.drawingFilename}`
    : "#";
};

const getAccessoryImage = (accessory) => {
  return accessory.imageFileName && accessory.imageFileName.length
    ? `${window.location.origin}/assets/images/products/accessories/${accessory.imageFileName}`
    : "https://placehold.co/128x128/fff/cecece?text=?";
};

const showDownloadButton = computed(() => {
  return state.printDownloadUrl.length > 0 && !state.isPrinting;
});

const printButtonText = computed(() => {
  return state.isPrinting
    ? "button_text_generating_pdf"
    : "button_text_generate_pdf";
});

const getDrawing = computed(() => {
  return state.selectionResult.drawingDimensions.drawingFile &&
    state.selectionResult.drawingDimensions.drawingFile
    ? `${window.location.origin}/assets/images/drawings/${state.selectionResult.drawingDimensions.drawingFile}`
    : "https://placehold.co/128x128/fff/cecece?text=?";
});

const getProductImage = computed(() => {
  return state.selectionResult.imageFilename &&
    state.selectionResult.imageFilename
    ? `${window.location.origin}/assets/images/products/${state.selectionResult.imageFilename}?width=200&height=200&rmode=max`
    : "https://placehold.co/128x128/fff/cecece?text=?";
});

const printProduct = () => {
  state.printDownloadUrl = "";
  state.isPrinting = true;

  fetch(`${import.meta.env.VITE_API_URL}/api/print/print`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: state.selectionResult.id,
      printType: "product",
      referrer: route.params.referrer,
      culture: "en",
      filename: state.selectionResult.model.trim(),
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      state.printDownloadUrl = data.filename;
      state.isPrinting = false;
    });
};

const activeTab = computed({
  get: () => {
    let tab = state.tabs.find((t) => {
      return t.current == true;
    });
    return tab?.id;
  },
  set: (val) => {
    let tab = state.tabs.find((t) => t.id == val);
    setActiveTab(tab);
  },
});

const setActiveTab = (tab) => {
  state.tabs.forEach((t) => {
    t.current = t.id == tab.id;
  });
};

const pages = computed(() => {
  return [
    { name: "Selections", href: "/selection", current: false },
    { name: state.selectionResult.model, href: "#", current: true },
  ];
});

const loading = computed(() => {
  return state.loading;
});

const pressureChartOptions = computed(() => {
  var opts = JSON.parse(state.selectionResult.charts.pressure);

  opts.xAxis[0].crosshair.label.formatter = (value) =>
    `${value.toFixed(2)} ${
      volumeFlowUnits.value.get(selectionInformation.value.volumeUnit)?.text
    }`;

  opts.yAxis[0].crosshair.label.formatter = (value) =>
    `${value.toFixed(0)} ${
      pressureUnits.value.get(selectionInformation.value.pressureUnit)?.text
    }`;

  opts.chart.events = {
    click: (e) => {
      let x = e.xAxis[0].value;
      let y = e.yAxis[0].value;
      selectFan(x, y);
    },
  };

  return opts;
});

const selectFan = (volume, pressure) => {
  state.loading = true;
  fetch(`${import.meta.env.VITE_API_URL}/api/technical/selectFan`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      resultId: state.selectionResult.id,
      referrer: route.params.referrer,
      volumeFlow: volume,
      pressure: pressure,
      accessories: state.selectionResult.accessories,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      state.printDownloadUrl = "";
      state.selectionResult = data;
      loadProduct();
      state.loading = false;
    });
};

const powerChartOptions = computed(() => {
  var opts = JSON.parse(state.selectionResult.charts.power);

  opts.xAxis[0].crosshair.label.formatter = (value) =>
    `${value.toFixed(2)} ${
      volumeFlowUnits.value.get(selectionInformation.value.volumeUnit)?.text
    }`;

  opts.yAxis[0].crosshair.label.formatter = (value) => `${value.toFixed(2)} kW`;
  return opts;
});

const efficiencyChartOptions = computed(() => {
  var opts = JSON.parse(state.selectionResult.charts.efficiency);

  opts.xAxis[0].crosshair.label.formatter = (value) =>
    `${value.toFixed(2)} ${
      volumeFlowUnits.value.get(selectionInformation.value.volumeUnit)?.text
    }`;

  opts.yAxis[0].crosshair.label.formatter = (value) => `${value.toFixed(1)} %`;
  return opts;
});

const decreaseAccessoryQuantity = (accessory) => {
  if (accessory.quantity > 0) {
    accessory.quantity--;
  }
  selectFan(0, 0);
};

const increaseAccessoryQuantity = (accessory) => {
  accessory.quantity++;
  selectFan(0, 0);
};

const loadProduct = () => {
  var resultItems =
    route.params.referrer == "selection"
      ? selectionResultItems.value
      : project.value.resultItems;

  if (state.selectionResult == null) {
    const result = resultItems.find((item) => item.id == route.params.id);
    state.selectionResult = JSON.parse(JSON.stringify(result));
    state.replaceId = state.selectionResult.id;
  }

  fetch(`${import.meta.env.VITE_API_URL}/api/technical/getTechnicalSection`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      resultId: state.selectionResult.id,
      referrer: route.params.referrer,
      include: [],
      exclude: [],
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      for (let i = 0; i < data.length; i++) {
        state.technicalSections[data[i].sectionId] = data[i];
      }
    });
};

const exportCsvData = async () => {
  const response = await fetch("/api/export/generate", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ids: [state.selectionResult.id],
      referrer: route.params.referrer,
    }),
  });

  if (response.ok) {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } else {
    console.error("Failed to download CSV");
  }
};

const addToProject = () => {
  state.addToProjectBusy = true;
  const id = selectionResultItems.value.find(
    (item) => item.id == route.params.id
  ).id;
  projectStore
    .addToProject(state.selectionResult, state.selectionResult.accessories)
    .then(() => {
      state.addToProjectBusy = false;
      notify(
        {
          group: "notifications",
          title: "Success",
          text: "Item was added to the project",
        },
        5000
      );
    });
};

const saveProjectSelection = () => {
  projectStore
    .saveProjectSelection(state.replaceId, state.selectionResult)
    .then(() => {
      notify(
        {
          group: "notifications",
          title: "Success",
          text: "Project item changes were saved",
        },
        5000
      );
      router.push("/project");
    });
};

const previousResult = () => {
  let previousId =
    selectionResultItems.value[
      selectionResultItems.value.findIndex(
        (i) => i.id == state.selectionResult.id
      ) - 1
    ].id;
  router.push(`/selection/${previousId}`);
  setActiveTab(state.tabs[0]);
  loadProduct();
};

const nextResult = () => {
  let nextId =
    selectionResultItems.value[
      selectionResultItems.value.findIndex(
        (i) => i.id == state.selectionResult.id
      ) + 1
    ].id;
  router.push(`/selection/${nextId}`);
  setActiveTab(state.tabs[0]);
  loadProduct();
};

loadProduct();
window.scrollTo({ top: 0 });
</script>

<style lang="scss">
#notifications-container {
  @apply xl:pb-6 lg:pb-6 pb-20;
}
// .highcharts-background,
// .highcharts-plot-background,
// .highcharts-plot-border {
//   fill: white;
// }
// .highcharts-crosshair {
//   stroke: lightgray;
//   stroke-dasharray: 5;
// }
// .highcharts-line-series {
//   fill: white;
//   stroke: green;
// }
// .highcharts-axis-labels {
//   font-size: 12px;
// }
</style>
