<template>
  <div
    v-if="state.loading || selectionInformation.softwareVersion.length == 0"
    class="absolute top-0 bottom-0 left-0 right-0 backdrop-blur-1 z-50 h-[50vh]"
  >
    <div class="m-auto block w-fit relative top-[48%]">
      <svg
        class="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-primary-500"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </div>
  </div>
  <div v-else class="grid grid-cols-12 gap-4 xs:px-2">
    <div class="col-span-8 xs:col-span-12">
      <div class="divide-y divide-gray-200 overflow-hidden bg-white shadow">
        <div class="px-4 py-2 text-md bg-primary text-white">
          <T keyName="card_title_products" />
        </div>
        <div class="px-0 py-2 pt-2">
          <div class="px-4">
            <label
              for="default-search"
              class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >Search</label
            >
            <div class="relative">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                class="block w-full p-3 py-2 pl-10 text-sm text-gray-900 border border-gray-300 bg-white focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search..."
                required
                v-model="state.productsSearchTerm"
              />
            </div>
          </div>
          <div class="my-2 px-4 py-2 bg-gray-100">
            <div class="grid grid-cols-12 gap-2">
              <div class="col-span-11">
                <ToggleBadge
                  class="mr-2 mt-1"
                  color="white"
                  selected-color="primary"
                  show-close-button="true"
                  v-for="tag in selectionInformation.productTags"
                  size="large"
                  :text="tag.text"
                  cursor="pointer"
                  :selected="tag.selected"
                  @toggled="tag.selected = !tag.selected"
                />
              </div>
              <div class="col-span-1">
                <div class="w-full flex justify-end">
                  <span class="isolate inline-flex shadow-sm">
                    <button
                      @click="toggleAllRanges(true)"
                      type="button"
                      class="relative inline-flex items-center border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                    >
                      All
                    </button>

                    <button
                      @click="toggleAllRanges(false)"
                      type="button"
                      class="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                    >
                      None
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="grid xl:grid-cols-6 lg:grid-cols-6 grid-cols-2 auto-cols-auto gap-3 px-4 max-h-[32vh] pb-1 xs:max-h-72 overflow-y-scroll"
          >
            <template v-for="range in filteredProductRanges">
              <FollowCursorPanel :show="false" class="p-2 w-64">
                <template #content>
                  <div class="flex flex-col space-y-1.5">
                    <div class="text-md font-medium">
                      {{ range.rangeId }}
                    </div>
                    <div class="text-sm">
                      {{ range.description }}
                    </div>
                    <div class="flex items-center space-x-2">
                      <template v-for="tag in range.tags">
                        <template v-if="tag.tags.length == 2">
                          <div
                            class="flex items-center bg-gray-200 py-1 px-1.5 shadow-sm"
                          >
                            <img
                              class="max-h-4 max-w-4 h-full w-auto"
                              :src="`/assets/images/site/icons/${tag.tags[1]}`"
                            />
                            <div class="text-xs ml-2">
                              {{ tag.tags[0] }}
                            </div>
                          </div>
                        </template>
                      </template>
                    </div>
                  </div>
                </template>
              </FollowCursorPanel>
              <div
                class="overflow-hidden bg-white shadow cursor-pointer border-[2px]"
                :class="{
                  'border-primary-500': range.selected,
                  'border-transparent': !range.selected,
                }"
                @click="toggleRange(range)"
                @mousemove="rangeItemHover(range, $event)"
                @mouseleave="range.hovered = false"
              >
                <div class="p-2 h-full flex flex-col justify-between">
                  <div class="relative flex items-center z-50 space-x-2">
                    <div class="flex h-5 items-center">
                      <input
                        aria-describedby="comments-description"
                        v-model="range.selected"
                        type="checkbox"
                        class="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                      />
                    </div>
                    <div class="text-sm">
                      <ToggleBadge
                        size="xs"
                        :text="range.rangeId"
                        cursor="pointer"
                        :selected="range.selected"
                        :show-close-button="false"
                        color="white"
                        selected-color="primary"
                      />
                    </div>
                  </div>
                  <img
                    :src="getRangeThumbnail(range)"
                    class="m-auto max-h-full my-2"
                  />
                  <div class="flex items-center space-x-2">
                    <template v-if="!range.published">
                      <ToggleBadge
                        class="bg-red-100 text-red-500"
                        size="xs"
                        text="Unpublished"
                        cursor="pointer"
                        :selected="false"
                        :show-close-button="false"
                        color="red"
                        selected-color="primary"
                      />
                    </template>
                    <template v-for="tag in range.tags">
                      <template v-if="tag.tags.length == 2">
                        <img
                          class="max-h-5 max-w-5 h-full w-auto"
                          :title="tag.tags[0]"
                          :src="`/assets/images/site/icons/${tag.tags[1]}`"
                        />
                      </template>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-4 xs:col-span-12">
      <div class="divide-y divide-gray-200 bg-white shadow">
        <div class="px-4 py-2 text-md bg-primary text-white">
          <T keyName="card_title_accessories" /> ({{ accessoriesCount }})
        </div>
        <div class="px-0 pt-2 pb-1.5">
          <div class="px-4">
            <label
              for="default-search"
              class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >Search</label
            >
            <div class="relative">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                class="block w-full p-3 py-2 pl-10 text-sm text-gray-900 border border-gray-300 bg-white focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search..."
                required
                v-model="state.accessoriesSearchTerm"
              />
            </div>
          </div>
          <div class="px-4 py-2">
            <ul
              class="w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 h-[calc(32vh+4.125rem)] overflow-y-scroll"
            >
              <li
                v-for="acc in filteredAccessories"
                class="w-full px-4 py-2 border-b border-gray-200 flex justify-between items-center"
              >
                <div>
                  {{ acc.productText }}
                </div>
                <div class="flex">
                  <div>
                    <Button
                      button-color="primary"
                      button-size="xs"
                      @click="decreaseQuantity(acc)"
                      :class="{
                        '!px-1 !py-1': true,
                        disabled: acc.quantity == 0,
                      }"
                    >
                      <template #content>
                        <MinusIcon
                          class="h-4 w-4 flex-shrink-0"
                          aria-hidden="true"
                        />
                      </template>
                    </Button>
                  </div>
                  <div>
                    <Input
                      class="w-10"
                      readonly
                      input-class="px-1 !py-1 text-xs text-center"
                      v-model="acc.quantity"
                    />
                  </div>
                  <div>
                    <Button
                      button-color="primary"
                      button-size="xs"
                      @click="increaseQuantity(acc)"
                      :class="{
                        '!px-1 !py-1': true,
                        disabled:
                          acc.maxQuantity && acc.quantity == acc.maxQuantity,
                      }"
                    >
                      <template #content>
                        <PlusIcon
                          class="h-4 w-4 flex-shrink-0"
                          aria-hidden="true"
                        />
                      </template>
                    </Button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12">
      <div class="divide-y divide-gray-200 bg-white shadow">
        <div class="px-4 py-2 text-md bg-primary text-white">
          <T keyName="card_title_selection_parameters" />
        </div>
        <div class="px-4 py-4">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-8 grid grid-cols-12 gap-2">
              <div class="col-span-4">
                <InputDropdown
                  label="input_label_volume"
                  :options="volumeFlowUnits"
                  placeholder="0.00"
                  :input-value="selectionInformation.volume"
                  :select-value="selectionInformation.volumeUnit"
                  @input-changed="volumeChanged"
                  @select-changed="volumeUnitChanged"
                  class="col-span-1"
                />
              </div>
              <div class="col-span-4">
                <div class="mb-1">
                  <label
                    for="value"
                    class="block text-sm font-medium text-gray-700"
                  >
                    <T keyName="input_label_pressure" />
                  </label>
                </div>
                <div class="grid grid-cols-12 gap-0">
                  <InputDropdown
                    :options="pressureUnits"
                    placeholder="0"
                    :input-value="selectionInformation.pressure"
                    :select-value="selectionInformation.pressureUnit"
                    @input-changed="pressureChanged"
                    @select-changed="pressureUnitChanged"
                    class="col-span-8 [&_*]:border-r-0"
                  />
                  <Dropdown
                    :options="pressureTypeOptions"
                    v-model="selectionInformation.pressureType"
                    class="col-span-4 [&_*]:border-l-0 text-gray-500"
                    color="primary"
                  />
                </div>
              </div>
              <div class="col-span-4">
                <div class="">
                  <label
                    for="value"
                    class="block text-sm font-medium text-gray-700"
                  >
                    <T keyName="label_air_conditions" />
                  </label>
                </div>
                <div class="grid grid-cols-12 gap-1">
                  <label
                    for="value"
                    class="col-span-9 py-2 mt-1.5 block text-xs font-medium text-gray-500"
                  >
                    {{ conditionsText }}
                  </label>
                  <Button
                    class="col-span-3 px-2 mt-1.5"
                    button-color="primary"
                    button-text="button_text_edit"
                    button-size="xs"
                    id="button-edit-air-conditions"
                    @click="state.conditionsOpen = !state.conditionsOpen"
                  />
                  <AirConditionsPopover
                    :selection-information="selectionInformation"
                    popover-id="popover-air-conditions"
                    trigger-id="button-edit-air-conditions"
                    trigger-mode="none"
                    placement="right"
                    class="w-80"
                    @conditions-updated="conditionsUpdated"
                    :open="state.conditionsOpen"
                    @close="state.conditionsOpen = false"
                  />
                </div>
              </div>
              <div class="col-span-5">
                <div class="grid grid-cols-2 gap-x-2 gap-y-1">
                  <label
                    for="value"
                    class="block text-sm font-medium text-gray-700 col-span-2"
                  >
                    Diameter
                  </label>
                  <div class="col-span-1">
                    <LeadingTrailingInput
                      color="primary"
                      leading-text="Min"
                      trailing-text="mm"
                      v-model="selectionInformation.minimumDiameter"
                      input-class="!pl-11 !pr-10"
                      class="col-span-2"
                    />
                  </div>
                  <div class="col-span-1">
                    <LeadingTrailingInput
                      color="primary"
                      leading-text="Max"
                      trailing-text="mm"
                      v-model="selectionInformation.maximumDiameter"
                      input-class="!pl-11 !pr-10"
                      class="col-span-2"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-5">
                <div class="grid grid-cols-2 gap-x-2 gap-y-1">
                  <label
                    for="value"
                    class="block text-sm font-medium text-gray-700 col-span-2"
                  >
                    Speed
                  </label>
                  <div class="col-span-1">
                    <LeadingTrailingInput
                      color="primary"
                      leading-text="Min"
                      trailing-text="rpm"
                      v-model="selectionInformation.minimumSpeed"
                      input-class="!pl-11 !pr-10"
                      class="col-span-2"
                    />
                  </div>
                  <div class="col-span-1">
                    <LeadingTrailingInput
                      color="primary"
                      leading-text="Max"
                      trailing-text="rpm"
                      v-model="selectionInformation.maximumSpeed"
                      input-class="!pl-11 !pr-10"
                      class="col-span-2"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-2">
                <div class="grid grid-cols-12 gap-x-2 gap-y-1">
                  <label
                    for="value"
                    class="block text-sm font-medium text-gray-700 col-span-12"
                  >
                    Sound Distance
                  </label>
                  <div class="col-span-12">
                    <LeadingTrailingInput
                      color="primary"
                      trailing-text="m"
                      placeholder="1.5"
                      v-model="selectionInformation.soundDistance"
                      input-class="!pl-3 !pr-10"
                      class="col-span-2"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-span-4 grid grid-cols-12 gap-2">
              <div class="col-span-12">
                <div class="grid grid-cols-1 gap-0">
                  <div class="col-span-1">
                    <div class="grid grid-cols-2 gap-0">
                      <DropdownCheckbox
                        class="col-span-2"
                        label="input_label_electrical_supply"
                        :items="selectionInformation.electricalSupplies"
                        v-model="selectedElectricalSupply"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-8">
                <div class="grid grid-cols-1 gap-x-2">
                  <RadioPanel
                    name="motor_sizing"
                    :options="state.motorSizingOptions"
                    label="input_label_motor_sizing"
                    label-class=""
                    v-model="selectionInformation.motorSizing"
                  />
                </div>
              </div>
              <div class="col-span-4">
                <LeadingTrailingInput
                  color="primary"
                  trailing-text="%"
                  input-class="!pl-3"
                  v-model="selectionInformation.serviceFactor"
                  label="input_label_service_factor"
                  label-class="mb-1"
                  class="col-span-2"
                />
              </div>

              <!-- <div class="col-span-1">
                <div
                  v-if="showSmokeVenting"
                  class="grid grid-cols-1 gap-0 mt-2"
                >
                  <Checkbox
                    label="input_label_smoke_venting"
                    v-model="selectionInformation.smokeVenting"
                  />
                </div>
              </div> -->
            </div>
          </div>

          <div class="grid grid-cols-12 gap-3 mt-3">
            <div class="col-span-12">
              <div class="flex mb-2">
                <nav class="-mb-px flex space-x-8">
                  <button
                    v-for="tab in state.optionTabs"
                    :key="tab.name"
                    @click="setOptionTabActive(tab)"
                    :class="[
                      tab.current
                        ? 'border-primary-500 text-primary-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                      'whitespace-nowrap pb-2 px-1 border-b-2 font-medium text-sm',
                    ]"
                    :aria-current="tab.current ? 'page' : undefined"
                  >
                    {{ tab.name }}
                  </button>
                </nav>
              </div>
              <template v-if="state.optionTabs[0].current">
                <div class="grid grid-cols-12">
                  <div class="col-span-4 grid grid-cols-2 gap-2">
                    <DropdownCheckbox
                      class="col-span-1"
                      :label="selectionInformation.installationTypes.name"
                      :items="selectionInformation.installationTypes.items"
                      v-model="selectedInstallationType"
                    >
                      <template #label>
                        <div class="flex justify-between items-center">
                          <T keyName="input_label_installation_type" />
                          <svg
                            id="info_ins_type"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            class="w-5 h-5 cursor-help"
                          >
                            <path
                              class="fill-primary-500"
                              d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
                            />
                          </svg>
                        </div>
                      </template>
                    </DropdownCheckbox>
                    <DropdownCheckbox
                      class="col-span-1"
                      :label="selectionInformation.formsOfRunning.name"
                      :items="selectionInformation.formsOfRunning.items"
                      v-model="selectedFormOfRunning"
                    >
                      <template #label>
                        <div class="flex justify-between items-center">
                          <T keyName="input_label_form_of_running" />
                          <svg
                            id="info_form_of_running"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            class="w-5 h-5 cursor-help"
                          >
                            <path
                              class="fill-primary-500"
                              d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
                            />
                          </svg>
                        </div>
                      </template>
                    </DropdownCheckbox>
                    <Popover
                      popover-id="pop_info_form_of_running"
                      trigger-mode="hover"
                      trigger-id="info_form_of_running"
                      placement="right"
                    >
                      <template #content>
                        <table>
                          <tr
                            v-for="item in selectionInformation.formsOfRunning
                              .items"
                          >
                            <td>
                              <img :src="item.imageFilePath" alt="" />
                            </td>
                            <td>
                              <div class="font-semibold">
                                <T :keyName="item.text" />
                              </div>
                              <T :keyName="item.description" />
                            </td>
                          </tr>
                        </table>
                      </template>
                    </Popover>
                    <Popover
                      popover-id="pop_info_ins_type"
                      trigger-mode="hover"
                      trigger-id="info_ins_type"
                      placement="right"
                    >
                      <template #content>
                        <table>
                          <tr
                            v-for="item in selectionInformation
                              .installationTypes.items"
                          >
                            <td>
                              <img :src="item.imageFilePath" alt="" />
                            </td>
                            <td>
                              <div class="font-semibold">
                                <T :keyName="item.text" />
                              </div>
                              <T :keyName="item.description" />
                            </td>
                          </tr>
                        </table>
                      </template>
                    </Popover>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-6">
                    <div class="font-medium text-sm text-gray-700 mb-1">
                      Arrangement
                    </div>
                    <div class="grid grid-cols-12 gap-2">
                      <template
                        v-for="arr in selectionInformation.construction
                          .arrangement.items"
                      >
                        <div class="col-span-2">
                          <div
                            class="overflow-hidden box-border w-full h-full max-h-24 border px-1 pt-1 cursor-pointer shadow-sm hover:shadow-md"
                            :class="{
                              'border-primary-500': arr.isSelected,
                            }"
                            @click="arr.isSelected = !arr.isSelected"
                          >
                            <div
                              class="relative flex flex-col h-full space-y-1"
                            >
                              <Checkbox
                                v-if="arr.isSelected"
                                v-model="arr.isSelected"
                                class="!absolute -top-1 -right-3.5 pointer-events-none"
                              />
                              <div
                                class="flex-1 min-h-0 flex items-center justify-center"
                              >
                                <img
                                  class="max-h-full max-w-full p-1"
                                  :src="`${arr.imageFilePath}?width=80&height=80&rmode=max`"
                                  alt=""
                                />
                              </div>
                              <div
                                class="text-center text-xs font-medium text-white bg-primary-500 -mx-1"
                              >
                                {{ arr.text }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="col-span-6">
                    <div class="grid grid-cols-12 mb-2">
                      <div class="col-span-8">
                        <RadioPanel
                          name="discharge"
                          :options="state.dischargeOptions.options"
                          label="Discharge"
                          label-class=""
                          v-model="state.dischargeOptions.selected"
                        />
                      </div>
                    </div>

                    <div class="grid grid-cols-12 gap-2">
                      <template v-for="dc in filteredDischargeOptions">
                        <div class="col-span-2">
                          <div
                            class="overflow-hidden box-border w-full h-full max-h-24 border px-1 pt-1 cursor-pointer shadow-sm hover:shadow-md"
                            :class="{
                              'border-primary-500': dc.isSelected,
                            }"
                            @click="dc.isSelected = !dc.isSelected"
                          >
                            <div
                              class="relative flex flex-col h-full space-y-1"
                            >
                              <Checkbox
                                v-if="dc.isSelected"
                                v-model="dc.isSelected"
                                class="!absolute -top-1 -right-3.5 pointer-events-none"
                              />
                              <div
                                class="flex-1 min-h-0 flex items-center justify-center"
                              >
                                <img
                                  class="max-h-full max-w-full p-1"
                                  :src="`${dc.imageFilePath}?width=80&height=80&rmode=max`"
                                  alt=""
                                />
                              </div>
                              <div
                                class="text-center text-xs font-medium text-white bg-primary-500 -mx-1"
                              >
                                {{ dc.text }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div class="grid grid-cols-4 gap-3 mt-3">
            <div v-if="user.userGroup.isAdmin" class="col-span-1">
              <Checkbox
                label="Enable debug"
                class="h-full"
                v-model="selectionInformation.enableDebug"
              />
            </div>
            <Button
              v-if="!state.selecting"
              class="col-span-1 col-start-4"
              button-color="primary"
              button-text="button_text_select"
              button-size="sm"
              @click="startSelection"
            />
            <Button
              v-else
              class="col-span-1 col-start-4"
              button-color="primary"
              button-text="button_text_stop"
              button-size="sm"
              @click="stopSelection"
            />
          </div>
        </div>
      </div>
    </div>

    <div id="selection-results" class="md:col-span-12 sm:col-span-12">
      <ItemsGrid
        :items="selectionResultItems"
        :grid-columns="selectionGridColumns"
        :loading="state.selecting"
        @actions-view-clicked="viewSelectionResult"
        @actions-add-to-project-clicked="addToProject"
        @actions-show-debug-clicked="showItemDebug"
        :current-page="state.currentPage"
        @page-changed="pageChanged"
      />
    </div>
  </div>
  <Modal
    :open="state.showErrorModal"
    @on-close="state.showErrorModal = false"
    panel-class="!max-w-sm"
  >
    <template #content>
      <div class="flex justify-center">
        <ExclamationTriangleIcon class="h-16 w-16 text-amber-500" />
      </div>
      <div class="flex justify-center">
        {{ state.errorMessage }}
      </div>
    </template>
  </Modal>

  <Modal
    :open="state.showItemDebugModal"
    @on-close="state.showItemDebugModal = false"
    panel-class="!max-w-72"
  >
    <template #content>
      <div class="flex justify-center">
        Showing debug: {{ state.itemDebugId }}
      </div>
      <div
        class="flex justify-center text-sm"
        v-html="state.itemDebugMessage"
      ></div>
    </template>
  </Modal>
</template>

<script setup>
import InputDropdown from "../components/shared/InputDropdown.vue";
import Input from "../components/shared/Input.vue";
import Button from "../components/shared/Button.vue";
import ItemsGrid from "../components/selection/ItemsGrid.vue";
import ToggleBadge from "../components/shared/ToggleBadge.vue";
import DropdownCheckbox from "../components/shared/DropdownCheckbox.vue";
import { useSelectionStore } from "../stores/selection";
import { useProjectStore } from "../stores/project";
import { storeToRefs } from "pinia";
import SelectionService from "../services/selection";
import { reactive, computed, watch, onMounted } from "vue";
import { HubConnectionState } from "@microsoft/signalr";
import { useRouter, useRoute } from "vue-router";
import LeadingTrailingInput from "../components/shared/LeadingTrailingInput.vue";
import Dropdown from "../components/shared/Dropdown.vue";
import AirConditionsPopover from "../components/selection/AirConditionsPopover.vue";
import FollowCursorPanel from "../components/shared/FollowCursorPanel.vue";
import cheetahJs from "cheetah-js";
import Checkbox from "../components/shared/Checkbox.vue";
import RadioPanel from "../components/shared/RadioPanel.vue";
import { useAuthStore } from "../stores/auth";
import { initTooltips } from "flowbite";
import { notify } from "notiwind";
import Modal from "../components/shared/Modal.vue";
import { fetchWrapper } from "../helpers/fetch-wrapper";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";
import { useGlobalStore } from "../stores/global";
import { PlusIcon, MinusIcon } from "@heroicons/vue/20/solid";
import Popover from "../components/shared/Popover.vue";
import { range } from "lodash";

const authStore = useAuthStore();
const projectStore = useProjectStore();
const globalStore = useGlobalStore();

const { user } = storeToRefs(authStore);
const router = useRouter();
const route = useRoute();
const { project } = storeToRefs(projectStore);

const selectionService = new SelectionService();
selectionService.onItemSelected = (item) => {
  selectionStore.addSelectionResultItem(item);
  initTooltips();
};

selectionService.onSelectionComplete = () => {
  state.selecting = false;
  localStorage.setItem("user", JSON.stringify(user.value));
};
const selectionStore = useSelectionStore();

const {
  volumeFlowUnits,
  pressureUnits,
  temperatureUnits,
  densityUnits,
  distanceUnits,
  selectionInformation,
  selectionResultItems,
} = storeToRefs(selectionStore);

const state = reactive({
  errorMessage: "",
  itemDebugMessage: "",
  itemDebugId: "",
  showErrorModal: false,
  showItemDebugModal: false,
  loading: false,
  productsSearchTerm: "",
  accessoriesSearchTerm: "",
  selecting: false,
  currentPage: 1,
  pageLength: 10,
  conditionsOpen: false,
  motorSizingOptions: [
    {
      text: "input_value_peak",
      value: "peak",
    },
    {
      text: "input_value_shaft",
      value: "shaft",
    },
  ],
  driveTypeOptions: [
    {
      text: "input_value_direct",
      value: "Direct",
    },
    {
      text: "input_value_belt",
      value: "Belt",
    },
  ],
  optionTabs: [
    {
      name: "Axial Options",
      current: true,
    },
    {
      name: "Centrifugal Options",
      current: false,
    },
  ],
  dischargeOptions: {
    selected: "clockwise",
    options: [
      {
        text: "Clockwise",
        value: "clockwise",
      },
      {
        text: "Counter Clockwise",
        value: "counter clockwise",
      },
    ],
  },
});

const setOptionTabActive = (tab) => {
  state.optionTabs.forEach((t) => {
    t.current = t.name == tab.name;
  });
};

const showItemDebug = (item) => {
  let messages = item.debug.join("<br />");
  state.itemDebugId = item.sku;
  state.itemDebugMessage = messages;
  state.showItemDebugModal = true;
};

watch(
  () => state.dischargeOptions.selected,
  () => {
    selectionInformation.value.construction.discharge.items.forEach(
      (d) =>
        (d.isSelected =
          d.group.toLowerCase() == state.dischargeOptions.selected)
    );
  }
);

const filteredDischargeOptions = computed(() => {
  return selectionInformation.value.construction.discharge.items.filter(
    (d) => d.group.toLowerCase() == state.dischargeOptions.selected
  );
});

const accessoriesCount = computed(() => {
  return selectionInformation.value.accessoriesList
    .map((a) => a.quantity)
    .reduce((partialSum, a) => partialSum + a, 0);
});

const increaseQuantity = (acc) => {
  acc.quantity += 1;
};

const decreaseQuantity = (acc) => {
  if (acc.quantity > 0) {
    acc.quantity -= 1;
  }
};

const toggleRange = (range) => {
  range.selected = !range.selected;
  selectionStore.clearSelectionResultItems();
};

const rangeItemHover = (range, event) => {
  range.hovered = true;

  const root = document.documentElement;

  root.style.setProperty("--mouse-x", event.pageX + "px");
  root.style.setProperty("--mouse-y", event.pageY + "px");
};

const setDefaultRanges = () => {
  if (route.query.ranges) {
    const ranges = decodeURIComponent(route.query.ranges).split(",");
    selectionInformation.value.productRanges.forEach((range) => {
      range.selected = ranges.indexOf(range.rangeId) > -1;
    });
  }
};

const filteredAccessories = computed(() => {
  return selectionInformation.value.accessoriesList.filter((a) => {
    return (
      a.productText
        .toLowerCase()
        .search(state.accessoriesSearchTerm.toLowerCase()) > -1
    );
  });
});

const projectCurrency = computed(() => {
  return project.value.currencyOptions.find(
    (c) => c.value == project.value.currency
  );
});

const selectionGridColumns = computed(() => {
  let columns = [
    {
      value: "model",
      text: "Model",
      sortable: true,
      fixed: true,
      width: 300,
    },
    {
      value: "deviation",
      text: "Performance (%)",
      sortable: true,
      width: 150,
    },

    {
      value: "velocity",
      text: "Velocity (m/s)",
      sortable: true,
      width: 130,
    },
    {
      value: "power",
      text: "Power (kW)",
      sortable: true,
      width: 110,
    },

    {
      value: "totalEfficiency",
      text: "Tot Eff (%)",
      sortable: true,
      width: 110,
    },
    {
      value: "soundLevel",
      text: "LpA dB(A)",
      sortable: true,
      width: 110,
    },
    {
      value: "speed",
      text: "Speed (rpm)",
      sortable: true,
      width: 120,
    },
    {
      value: "price",
      text: `Price (${projectCurrency.value.value})`,
      sortable: true,
      width: 110,
    },
    {
      value: "fanPrice",
      text: `FP (${projectCurrency.value.value})`,
      sortable: true,
      width: 110,
    },
    {
      value: "motorPrice",
      text: `MP (${projectCurrency.value.value})`,
      sortable: true,
      width: 110,
    },
  ];
  if (!user.value.userGroup.permissions.pricing.showSalePricing) {
    columns = columns.filter(
      (c) =>
        c.value != "price" && c.value != "fanPrice" && c.value != "motorPrice"
    );
  }
  return columns;
});

const pressureTypeOptions = computed(() => {
  return [
    { text: "label_static", value: "Static" },
    { text: "label_total", value: "Total" },
  ];
});

const filteredProductRanges = computed(() => {
  let selectedTags = selectionInformation.value?.productTags
    .filter((t) => t.selected)
    .map((t) => t.value);
  let ranges = [];
  selectionInformation.value.productRanges.forEach((r) => {
    if (selectedTags.length == 0 && state.productsSearchTerm.length == 0) {
      ranges.push(r);
    } else {
      if (
        (state.productsSearchTerm.length > 0 &&
          r.rangeId
            .trim()
            .toLowerCase()
            .indexOf(state.productsSearchTerm.trim().toLowerCase()) > -1) ||
        r.tags.map((t) => t.value).some((t) => selectedTags.includes(t))
      ) {
        ranges.push(r);
      }
    }
  });
  if (
    ranges.filter((r) => {
      r.smokeVenting;
    }).length == 0
  ) {
    selectionInformation.value.smokeVenting = false;
  }
  return ranges.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));
});

const showSmokeVenting = computed(() => {
  return (
    filteredProductRanges.value.filter((r) => r.selected && r.smokeVenting)
      .length > 0
  );
});

const showDriveType = computed(() => {
  return (
    filteredProductRanges.value.filter((r) => r.selected && r.driveType)
      .length > 0
  );
});

const toggleAllRanges = (selected) => {
  filteredProductRanges.value.forEach((r) => {
    r.selected = selected;
  });

  selectionStore.clearSelectionResultItems();
};

const conditionsText = computed(() => {
  let temperature = `${
    selectionInformation.value.conditions.operatingTemperature
  } ${
    temperatureUnits.value.get(
      cheetahJs.units.Enums.TemperatureUnits[
        selectionInformation.value.conditions.temperatureUnit
      ]
    )?.text
  }`;

  let altitude = `${selectionInformation.value.conditions.altitude} ${
    distanceUnits.value.get(
      cheetahJs.units.Enums.DistanceUnits[
        selectionInformation.value.conditions.altitudeUnit
      ]
    )?.text
  }`;

  let density = `${selectionInformation.value.conditions.calculatedDensity} ${
    densityUnits.value.get(
      cheetahJs.units.Enums.DensityUnits[
        selectionInformation.value.conditions.densityUnit
      ]
    )?.text
  }`;

  return `${temperature} @ ${altitude} (${density})`;
});

const conditionsUpdated = (conditions) => {
  selectionInformation.value.conditions.altitude = conditions.altitude;
  selectionInformation.value.conditions.altitudeUnit = conditions.altitudeUnit;
  selectionInformation.value.conditions.inletPressure =
    conditions.inletPressure;
  selectionInformation.value.conditions.pressureUnit = conditions.pressureUnit;
  selectionInformation.value.conditions.operatingTemperature =
    conditions.operatingTemperature;
  selectionInformation.value.conditions.temperatureUnit =
    conditions.temperatureUnit;
  selectionInformation.value.conditions.calculatedDensity =
    conditions.calculatedDensity.toFixed(3);
};

const selectedElectricalSupply = computed({
  get() {
    return selectionInformation.value.electricalSupplies.find(
      (es) => es.isSelected
    );
  },
  set(item) {
    selectionInformation.value.electricalSupplies.forEach(
      (es) => (es.isSelected = es.value == item.value)
    );
  },
});

const selectedInstallationType = computed({
  get() {
    return selectionInformation.value.installationTypes.items.find(
      (es) => es.isSelected
    );
  },
  set(item) {
    selectionInformation.value.installationTypes.items.forEach(
      (es) => (es.isSelected = es.value == item.value)
    );
    selectionInformation.value.installationTypes.selectedValue =
      selectedInstallationType.value.value;
  },
});

const selectedFormOfRunning = computed({
  get() {
    return selectionInformation.value.formsOfRunning.items.find(
      (es) => es.isSelected
    );
  },
  set(item) {
    selectionInformation.value.formsOfRunning.items.forEach(
      (es) => (es.isSelected = es.value == item.value)
    );
    selectionInformation.value.formsOfRunning.selectedValue =
      selectedFormOfRunning.value.value;
  },
});

const pageChanged = (value) => {
  state.currentPage = value;
};

const volumeUnitChanged = (value) => {
  selectionInformation.value.volumeUnit = value;
};

const volumeChanged = (value) => {
  selectionInformation.value.volume = parseFloat(value);
};

const pressureUnitChanged = (value) => {
  selectionInformation.value.pressureUnit = value;
};

const pressureChanged = (value) => {
  selectionInformation.value.pressure = parseFloat(value);
};

const viewSelectionResult = (item) => {
  router.push({
    name: "technical",
    params: { id: item.id, referrer: "selection" },
  });
};

const addToProject = (item) => {
  projectStore.addToProject(item, item.accessories).then(() => {
    notify(
      {
        group: "notifications",
        title: "Success",
        text: "Item was added to the project",
      },
      5000
    );
  });
};

const getRangeThumbnail = (range) => {
  return range.image && range.image.length
    ? `${window.location.origin}/assets/images/products/${range.image}?width=100&height=100&rmode=max`
    : "https://placehold.co/128x128/fff/cecece?text=?";
};

const startSelection = () => {
  state.currentPage = 1;
  state.selecting = true;
  selectionStore.clearSelectionResultItems();

  selectionService
    .startSelection({
      ...selectionInformation.value,
      volume:
        selectionInformation.value.volume == null
          ? 0
          : selectionInformation.value.volume,
      pressure:
        selectionInformation.value.pressure == null
          ? 0
          : selectionInformation.value.pressure,
      connectionId: selectionService.connectionId,
    })
    .catch((err) => {
      if (err.name && err.name != "AbortError") {
        state.errorMessage = err;
        state.showErrorModal = true;
        state.selecting = false;
      }
    });
};

const stopSelection = () => {
  selectionService.stopSelection();
  selectionStore.setSelectionResultItems(selectionResultItems.value);
};

onMounted(() => {
  if (
    globalStore.previousRoute.path == "/project" ||
    globalStore.previousRoute.path.indexOf("/technical") > -1
  ) {
    selectionStore.disableClearResults = true;
  }
  if (
    selectionService.hubConnection?.state != HubConnectionState.Connected &&
    selectionService.hubConnection?.state != HubConnectionState.Connecting
  )
    selectionService.startConnection();
  watch(
    () => route.query,
    () => {
      setDefaultRanges();
    },
    { immediate: true, deep: true }
  );
  watch(
    () => {
      return {
        volume: selectionInformation.value.volume,
        volumeUnit: selectionInformation.value.volumeUnit,
        pressure: selectionInformation.value.pressure,
        pressureUnit: selectionInformation.value.pressureUnit,
        conditions: selectionInformation.value.conditions,
        pressureType: selectionInformation.value.pressureType,
        installationTypes: selectionInformation.value.installationTypes,
        formsOfRunning: selectionInformation.value.formsOfRunning,
        minimumDiameter: selectionInformation.value.minimumDiameter,
        maximumDiameter: selectionInformation.value.maximumDiameter,
        electricalSupplies: selectionInformation.value.electricalSupplies,
        minimumSpeed: selectionInformation.value.minimumSpeed,
        maximumSpeed: selectionInformation.value.maximumSpeed,
        motorSizing: selectionInformation.value.motorSizing,
        soundDistance: selectionInformation.value.soundDistance,
        serviceFactor: selectionInformation.value.serviceFactor,
        accessoriesList: selectionInformation.value.accessoriesList,
      };
    },
    () => {
      if (!selectionStore.disableClearResults) {
        selectionStore.clearSelectionResultItems();
      } else {
        selectionStore.disableClearResults = false;
      }
    },
    { immediate: true, deep: true }
  );
  initTooltips();
});
</script>

<style lang="scss">
#pop_info_ins_type,
#pop_info_form_of_running {
  table {
    tr {
      &:first-of-type {
        td {
          @apply pt-0;
        }
      }
      &:last-of-type {
        td {
          @apply pb-0;
        }
      }
      td {
        @apply text-xs items-center px-3 py-1 font-medium;
        &:first-of-type {
          @apply pl-0;
        }
        &:last-of-type {
          @apply pr-0;
        }
      }
    }
  }
}
#pop_info_ins_type {
  table {
    img {
      @apply m-auto max-w-[6rem] max-h-[3rem];
    }
  }
}

#pop_info_form_of_running {
  table {
    img {
      @apply m-auto max-w-[4rem] max-h-[4rem];
    }
  }
}
</style>
