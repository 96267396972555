<template>
  <EasyDataTable
    ref="dataTable"
    :headers="props.gridColumns"
    :items="props.items"
    :loading="props.loading"
    rows-per-page="10"
    hide-footer
    class=""
    body-item-class-name="text-center"
    fixed-headers
  >
    <template v-for="column in props.gridColumns" #[getColumnSlotName(column)]>
      {{ column.text }}
    </template>
    <template #item-model="item">
      <div class="inline-flex w-full pl-3 py-1">
        <div
          v-if="selectionInformation.enableDebug"
          class="flex items-center justify-center"
        >
          <template v-if="user.userGroup.isAdmin">
            <div title="REJECTED">
              <XMarkIcon
                @click="showDebug(item)"
                v-if="item.rejected"
                class="mt-2 h-5 w-5 mr-2 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div title="ISSUES FOUND">
              <ExclamationTriangleIcon
                @click="showDebug(item)"
                v-if="item.hasWarning || (item.debug && item.debug.length > 0)"
                class="mt-2 h-5 w-5 mr-2 text-yellow-400 cursor-pointer hover:bg-gray-200"
                aria-hidden="true"
              />
            </div>
          </template>
          <template v-else>
            <div title="ISSUES FOUND">
              <ExclamationTriangleIcon
                v-if="item.hasWarning"
                class="mt-2 h-5 w-5 mr-2 text-yellow-400"
                aria-hidden="true"
              />
            </div>
          </template>
        </div>

        <Button
          button-color="primary"
          button-size="xs"
          class="mr-2 cursor-pointer !px-2 !z-10"
          @click="emits('actions-view-clicked', item)"
          :data-tooltip-target="`view-button-tooltip-${item.id}`"
          data-tooltip-placement="bottom"
        >
          <template #content>
            <MagnifyingGlassIcon class="w-4" />
          </template>
        </Button>
        <div
          :id="`view-button-tooltip-${item.id}`"
          role="tooltip"
          class="text-xs absolute invisible z-50 inline-block px-2 py-1 font-medium text-gray-800 transition-opacity duration-300 bg-gray-200 opacity-0 shadow-sm tooltip"
        >
          View
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
        <Button
          button-color="primary"
          button-size="xs"
          class="mr-3 cursor-pointer !px-2"
          @click="emits('actions-add-to-project-clicked', item)"
          :data-tooltip-target="`add-button-tooltip-${item.id}`"
          data-tooltip-placement="bottom"
        >
          <template #content>
            <PlusIcon class="w-4" />
          </template>
        </Button>
        <div
          :id="`add-button-tooltip-${item.id}`"
          role="tooltip"
          class="absolute z-50 invisible inline-block px-2 py-1 text-xs font-medium text-gray-800 transition-opacity duration-300 bg-gray-200 shadow-sm opacity-0 tooltip"
        >
          Add to project
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
        <label
          class="py-2 font-medium"
          :class="{ 'text-red-500': item.rejected }"
        >
          {{ item.model }}
        </label>
      </div>
    </template>
    <template #item-price="item">
      {{
        pricing.toCurrencyString(
          item.price * projectCurrency.conversionRate,
          projectCurrency
        )
      }}
    </template>
    <template #item-fan-price="item">
      {{
        pricing.toCurrencyString(
          item.fanPrice * projectCurrency.conversionRate,
          projectCurrency
        )
      }}
    </template>
    <template #item-motor-price="item">
      {{
        pricing.toCurrencyString(
          item.motorPrice * projectCurrency.conversionRate,
          projectCurrency
        )
      }}
    </template>
    <!-- <template #item-actions="item">
      <div class="bg-white h-100 w-100">
        <Button
          button-color="primary"
          button-text="button_text_view"
          button-size="xs"
          @click="emits('actions-view-clicked', item)"
        />
      </div>
    </template> -->
    <template #loading>
      <div
        role="status"
        class="absolute top-[calc(50%-2rem)] left-[calc(50%-2rem)]"
      >
        <svg
          class="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-primary-500"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
      </div>
    </template>
  </EasyDataTable>
  <div class="flex items-center justify-between border-t border-gray-200 py-3">
    <div class="flex flex-1 justify-between sm:hidden">
      <a
        href="#"
        class="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >Previous</a
      >
      <a
        href="#"
        class="relative ml-3 inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >Next</a
      >
    </div>
    <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div>
        <p class="text-xs text-gray-700">
          Showing
          {{ " " }}
          <span class="font-medium">{{ currentPageFirstIndex }}</span>
          {{ " " }}
          to
          {{ " " }}
          <span class="font-medium">{{ currentPageLastIndex }}</span>
          {{ " " }}
          of
          {{ " " }}
          <span class="font-medium">{{ clientItemsLength }}</span>
          {{ " " }}
          results
        </p>
      </div>
      <div>
        <nav
          class="isolate inline-flex -space-x-px shadow-sm"
          aria-label="Pagination"
        >
          <button
            @click="prevPage"
            class="relative inline-flex items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            :disabled="isFirstPage"
          >
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <template v-for="p in paginationRange" :key="p">
            <button
              @click="p == `...` ? null : updatePage(p)"
              :class="[
                p === currentPaginationNumber ? activePageClass : pageClass,
              ]"
            >
              {{ p }}
            </button>
          </template>
          <button
            @click="nextPage"
            class="relative inline-flex items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            :disabled="isLastPage"
          >
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowTopRightOnSquareIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
  ExclamationTriangleIcon,
  PlusIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/vue/20/solid";
import { computed, ref, watch } from "vue";
import Button from "../shared/Button.vue";
import { useProjectStore } from "../../stores/project";
import { useSelectionStore } from "../../stores/selection";
import { storeToRefs } from "pinia";
import * as pricing from "../../helpers/pricing";
import { useAuthStore } from "../../stores/auth";

const { user } = storeToRefs(useAuthStore());
const projectStore = useProjectStore();
const { project } = storeToRefs(projectStore);

const selectionStore = useSelectionStore();
const { selectionInformation } = storeToRefs(selectionStore);

const dataTable = ref();

const props = defineProps(["items", "gridColumns", "loading", "currentPage"]);

const showDebug = (item) => {
  emits("actions-show-debug-clicked", item);
};

watch(
  () => props.currentPage,
  (value) => {
    if (value !== currentPaginationNumber.value) updatePage(value);
  }
);

const projectCurrency = computed(() => {
  return project.value.currencyOptions.find(
    (c) => c.value === project.value.currency
  );
});

const emits = defineEmits([
  "actions-view-clicked",
  "page-changed",
  "actions-add-to-project-clicked",
  "actions-show-debug-clicked",
]);

const activePageClass =
  "relative z-10 inline-flex items-center border border-primary-500 bg-primary px-4 py-2 text-sm font-medium text-white focus:z-20";
const pageClass =
  "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20";

const getColumnSlotName = (col) => {
  return "header-" + col.value;
};

// index related
const currentPageFirstIndex = computed(
  () => dataTable.value?.currentPageFirstIndex
);
const currentPageLastIndex = computed(
  () => dataTable.value?.currentPageLastIndex
);
const clientItemsLength = computed(() => dataTable.value?.clientItemsLength);

// pagination related
const maxPaginationNumber = computed(
  () => dataTable.value?.maxPaginationNumber
);
const currentPaginationNumber = computed(
  () => dataTable.value?.currentPaginationNumber
);

const isFirstPage = computed(() => currentPaginationNumber.value === 1);
const isLastPage = computed(
  () => currentPaginationNumber.value === maxPaginationNumber.value
);

const nextPage = () => {
  if (!isLastPage.value) {
    dataTable.value.nextPage();
    emits("page-changed", currentPaginationNumber.value);
  }
};
const prevPage = () => {
  if (!isFirstPage.value) {
    dataTable.value.prevPage();
    emits("page-changed", currentPaginationNumber.value);
  }
};
const updatePage = (paginationNumber) => {
  dataTable.value.updatePage(paginationNumber);
  emits("page-changed", currentPaginationNumber.value);
};
const firstPage = () => {
  updatePage(1);
};
const lastPage = () => {
  updatePage(maxPaginationNumber.value);
};

const paginationRange = computed(() => {
  const current = currentPaginationNumber.value;
  const max = maxPaginationNumber.value;
  const range = [];

  // Show the first page, the last page, and a range around the current page
  const start = Math.max(current - 1, 1);
  const end = Math.min(current + 1, max);

  if (start > 1) range.push(1);
  if (start > 2) range.push("...");

  for (let i = start; i <= end; i++) {
    range.push(i);
  }

  if (end < max - 1) range.push("...");
  if (end < max) range.push(max);

  return range;
});

// rows per page related
const rowsPerPageOptions = computed(() => dataTable.value?.rowsPerPageOptions);
const rowsPerPageActiveOption = computed(
  () => dataTable.value?.rowsPerPageActiveOption
);

const updateRowsPerPageSelect = (e) => {
  dataTable.value.updateRowsPerPageActiveOption(e.target.value);
};
</script>
