<template>
  <div class="flex min-h-full flex-col justify-center py-4 xs:px-2">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="text-center text-3xl tracking-tight text-gray-700 font-light">
        Sign in to your account
      </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-6 px-6 shadow">
        <div class="space-y-4">
          <div>
            <Input v-model="state.email" input-type="email" label="Email address" @input-type="keyUp" />
          </div>

          <div>
            <Input v-model="state.password" label="Password" input-type="password" @input-type="keyUp" />
          </div>

          <div class="flex items-end justify-start">
            <!-- <div class="flex items-center">
              <Checkbox label="Remember me" />
            </div> -->

            <div class="text-sm">
              <a href="#" @click="router.push(`/forgot-password`)"
                class="font-medium text-primary hover:text-primary-600">Forgot your password?</a>
            </div>
          </div>

          <div>
            <button :disabled="state.loading"
              class="flex w-full justify-center bg-primary py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              @click="doLogin">
              Sign in
            </button>
          </div>
        </div>

        <div class="mt-6">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300" />
            </div>
            <div class="relative flex justify-center text-sm">
              <span class="bg-white px-2 text-gray-500">Don't have an account?</span>
            </div>
          </div>

          <div class="mt-6 grid grid-cols-3 gap-3">
            <button
              class="flex w-full col-span-3 justify-center bg-primary py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              @click="router.push(`/register`)">
              Create account
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Input from "../components/shared/Input.vue";
import Checkbox from "../components/shared/Checkbox.vue";
import { reactive, watch, onMounted } from "vue";
import { useAuthStore } from "../stores/auth";
import { useRouter, useRoute } from "vue-router";
import { notify } from "notiwind";
import { fetchWrapper } from "../helpers/fetch-wrapper";

const router = useRouter();
const route = useRoute();

const authStore = useAuthStore();

const state = reactive({
  email: "",
  password: "",
  token: "",
  loading: false,
});

const keyUp = (e) => {
  if (e.key === "Enter") {
    doLogin();
  }
};

const doLogin = () => {
  state.loading = true;
  authStore.login(state.email, state.password).catch((err) => {
    state.loading = false;
    notify(
      {
        group: "notifications",
        title: "Error",
        text: err,
        type: "error",
      },
      5000
    );
    console.log(err);
  });
};

const verifyEmail = () => {
  fetchWrapper
    .post(`${import.meta.env.VITE_API_URL}/api/user/verify-email`, {
      token: state.token,
    })
    .then(() => {
      notify(
        {
          group: "notifications",
          title: "Email verified",
          text: "When approved, you can log in.",
        },
        5000
      );
      router.push("/login");
    });
};

onMounted(() => {
  watch(
    () => route.query,
    () => {
      if (route.query.token) {
        state.token = route.query.token;
        verifyEmail();
      }
    },
    { immediate: true, deep: true }
  );
});
</script>

<style lang="scss" scoped></style>
