<template>
  <template v-if="isLoaded">
    <TolgeeProvider :config="tolgeeConfig">
      <RouterView />
    </TolgeeProvider>
  </template>
</template>

<script setup>
import { RouterLink, RouterView } from "vue-router";
import { TolgeeProvider } from "@tolgee/vue";
import { reactive, ref, watch, onMounted, onBeforeMount } from "vue";
import { useGlobalStore } from "./stores/global";

const globalStore = useGlobalStore();

const isLoaded = ref(false);

const tolgeeConfig = reactive({
  availableLanguages: ["en" /*, "de", "cs", "fr"*/],
  defaultLanguage: "en",
  staticData: {},
});

onBeforeMount(() => {
  globalStore.getLanguage().then((response) => {
    tolgeeConfig.staticData = response;
    isLoaded.value = true;
  });
});
</script>
