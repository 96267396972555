<template>
  <div
    id="consent-banner"
    class="z-[99] flex fixed p-3 bg-white bottom-2 right-3 left-3 shadow justify-between items-center text-gray-800"
  >
    <div class="flex flex-col">
      <div class="font-medium text-lg flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 mr-2"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12q0-1.875.725-3.675T4.75 5.112t3.125-2.275t4-.862q.525 0 1.075.05t1.125.175q-.225 1.125.15 2.125t1.125 1.662t1.788.913t2.137-.125q-.65 1.475.188 2.825T21.95 11q.025.275.038.512t.012.513q0 2.05-.788 3.862t-2.137 3.175t-3.175 2.15T12 22m-1.5-12q.625 0 1.063-.437T12 8.5t-.437-1.062T10.5 7t-1.062.438T9 8.5t.438 1.063T10.5 10m-2 5q.625 0 1.063-.437T10 13.5t-.437-1.062T8.5 12t-1.062.438T7 13.5t.438 1.063T8.5 15m6.5 1q.425 0 .713-.288T16 15t-.288-.712T15 14t-.712.288T14 15t.288.713T15 16"
          />
        </svg>
        Cookies
      </div>
      <div class="text-sm">
        We use cookies to improve your experience on our site. By clicking
        "Accept All" you accept the use of cookies.
      </div>
    </div>
    <div class="flex space-x-3">
      <!-- <button
        @click="state.preferencesOpen = true"
        class="text-sm text-gray-700 px-4 py-2 bg-gray-50 hover:bg-gray-100"
      >
        Preferences
      </button> -->
      <button
        @click="acceptAll()"
        class="text-sm text-white bg-green-500 px-4 py-2 hover:bg-green-600"
      >
        Accept
      </button>
    </div>
  </div>
  <Modal
    :open="state.preferencesOpen"
    class="!max-w-md"
    @on-close="state.preferencesOpen = false"
  >
    <template #content> </template>
  </Modal>
</template>

<script setup>
import { reactive } from "vue";
import Modal from "../shared/Modal.vue";
import cookie from "cookiejs";
import { useGlobalStore } from "../../stores/global";

const globalStore = useGlobalStore();

const state = reactive({
  preferencesOpen: false,
});

const acceptAll = () => {
  cookie.set("cookie_consent", "true", { expires: 180 });
  globalStore.cookiesAccepted = true;
};
</script>

<style lang="scss" scoped>
#consent-banner {
}
</style>
