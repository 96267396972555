<template>
  <span
    class="inline-flex items-center font-medium"
    :class="buttonClass"
    @click="emits('toggled', props.tagId)"
  >
    <T :keyName="props.text" />
    <button
      v-if="props.selected && props.showCloseButton"
      type="button"
      class="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center focus:text-white focus:outline-none text-primary-400 hover:bg-primary-200 hover:text-primary-500 focus:bg-primary-500"
    >
      <svg class="h-2 w-2" stroke="white" fill="none" viewBox="0 0 8 8">
        <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
      </svg>
    </button>
  </span>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps([
  "size",
  "color",
  "selectedColor",
  "selected",
  "tagId",
  "text",
  "cursor",
  "showCloseButton",
]);
const emits = defineEmits(["toggled"]);

const buttonClass = computed(() => {
  let className =
    props.size == "small"
      ? "py-0.5 text-xs " +
        (props.selected && props.showCloseButton ? "pl-2 pr-0.5" : "px-2")
      : "py-0.5 text-xs " +
        (props.selected && props.showCloseButton ? "pl-2.5 pr-1" : "px-2.5");

  className += props.selected
    ? " bg-" + props.selectedColor + " text-white"
    : " bg-" + props.color + " text-primary";

  className += " cursor-" + props.cursor ?? "default";

  return className;
});
</script>

<style scoped></style>
