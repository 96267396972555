import { ref, computed, reactive } from "vue";
import { defineStore } from "pinia";
import cheetahJs from "cheetah-js";
import { fetchWrapper } from "../helpers/fetch-wrapper";

const baseUrl = `${import.meta.env.VITE_API_URL}/api/selection`;

export const useSelectionStore = defineStore("selection", () => {
  const selectionInformation = reactive({
    databaseVersion: "",
    softwareVersion: "",
    volume: null,
    pressure: null,
    volumeUnit: cheetahJs.units.Enums.VolumeFlowUnits.M3H,
    pressureUnit: cheetahJs.units.Enums.PressureUnits.Pa,
    hertz: "hertz50",
    conditions: {
      altitudeUnit: cheetahJs.units.Enums.DistanceUnits.M,
      densityUnit: cheetahJs.units.Enums.DensityUnits.KgM3,
      temperatureUnit: cheetahJs.units.Enums.TemperatureUnits.DegreeC,
    },
    construction: null,
    productRanges: [],
    productTags: [],
    electricalSupplies: [],
    installationTypes: [],
    formsOfRunning: [],
    accessoriesList: [],
    minimumDuty: null,
    maximumDuty: null,
    pressureType: null,
    smokeVenting: null,
    motorSizing: null,
    serviceFactor: null,
    driveType: null,
    pressureUnit: cheetahJs.units.Enums.PressureUnits.Pa,
    volumeUnit: cheetahJs.units.Enums.VolumeFlowUnits.M3H,
    temperatureUnit: cheetahJs.units.Enums.TemperatureUnits.DegreeC,
    enableDebug: null,
  });
  const disableClearResults = ref(false);
  const selectionResultItems = ref(
    localStorage.getItem("selection-results")
      ? JSON.parse(localStorage.getItem("selection-results"))
      : []
  );
  const volumeFlowUnits = computed(() => {
    return new Map(
      [...cheetahJs.units.VolumeFlow.entries()].filter(
        (entry) =>
          entry[0] != cheetahJs.units.Enums.VolumeFlowUnits.Undefined &&
          (entry[0] == cheetahJs.units.Enums.VolumeFlowUnits.M3S ||
            entry[0] == cheetahJs.units.Enums.VolumeFlowUnits.M3M ||
            entry[0] == cheetahJs.units.Enums.VolumeFlowUnits.M3H ||
            entry[0] == cheetahJs.units.Enums.VolumeFlowUnits.CFS ||
            entry[0] == cheetahJs.units.Enums.VolumeFlowUnits.CFM ||
            entry[0] == cheetahJs.units.Enums.VolumeFlowUnits.CFH ||
            entry[0] == cheetahJs.units.Enums.VolumeFlowUnits.LS)
      )
    );
  });

  const pressureUnits = computed(() => {
    return new Map(
      [...cheetahJs.units.Pressure.entries()].filter(
        (entry) =>
          entry[0] != cheetahJs.units.Enums.PressureUnits.Undefined &&
          (entry[0] == cheetahJs.units.Enums.PressureUnits.Pa ||
            entry[0] == cheetahJs.units.Enums.PressureUnits.KPa ||
            entry[0] == cheetahJs.units.Enums.PressureUnits.MMwg ||
            entry[0] == cheetahJs.units.Enums.PressureUnits.Inwg ||
            entry[0] == cheetahJs.units.Enums.PressureUnits.Bar ||
            entry[0] == cheetahJs.units.Enums.PressureUnits.Psi)
      )
    );
  });

  const densityUnits = computed(() => {
    return new Map(
      [...cheetahJs.units.Density.entries()].filter(
        (entry) => entry[0] != cheetahJs.units.Enums.DensityUnits.Undefined
      )
    );
  });
  const distanceUnits = computed(() => {
    return new Map(
      [...cheetahJs.units.Distance.entries()].filter(
        (entry) =>
          entry[0] != cheetahJs.units.Enums.DistanceUnits.Undefined &&
          (entry[0] == cheetahJs.units.Enums.DistanceUnits.M ||
            entry[0] == cheetahJs.units.Enums.DistanceUnits.FT)
      )
    );
  });
  const temperatureUnits = computed(() => {
    return new Map(
      [...cheetahJs.units.Temperature.entries()].filter(
        (entry) =>
          entry[0] != cheetahJs.units.Enums.TemperatureUnits.Undefined &&
          (entry[0] == cheetahJs.units.Enums.TemperatureUnits.DegreeC ||
            entry[0] == cheetahJs.units.Enums.TemperatureUnits.DegreeF)
      )
    );
  });
  function getSelectionInformation(userId) {
    return fetchWrapper.post(`${baseUrl}/getSelectionInformation`, {
      userId,
    });
  }
  function clearSelectionResultItems() {
    selectionResultItems.value = [];
  }
  function addSelectionResultItem(item) {
    selectionResultItems.value.push(item);
  }
  function setSelectionResultItems(items) {
    selectionResultItems.value = items;
    localStorage.setItem(
      "selection-results",
      JSON.stringify(selectionResultItems.value)
    );
  }
  function setSelectionInformation(data) {
    selectionInformation.productRanges = data.productRanges;
    selectionInformation.productTags = data.productTags;
    selectionInformation.electricalSupplies = data.electricalSupplies;
    selectionInformation.installationTypes = data.installationTypes;
    selectionInformation.formsOfRunning = data.formsOfRunning;
    selectionInformation.accessoriesList = data.accessoriesList;
    selectionInformation.minimumDuty = data.minimumDuty;
    selectionInformation.maximumDuty = data.maximumDuty;
    selectionInformation.pressureType = data.pressureType;
    selectionInformation.conditions = data.conditions;
    selectionInformation.smokeVenting = data.smokeVenting;
    selectionInformation.motorSizing = data.motorSizing;
    selectionInformation.serviceFactor = data.serviceFactor;
    selectionInformation.driveType = data.driveType;
    selectionInformation.volumeUnit = data.volumeUnit;
    selectionInformation.pressureUnit = data.pressureUnit;
    selectionInformation.softwareVersion = data.softwareVersion;
    selectionInformation.databaseVersion = data.databaseVersion;
    selectionInformation.enableDebug = data.enableDebug;
    selectionInformation.construction = data.construction;
  }
  return {
    selectionInformation,
    selectionResultItems,
    volumeFlowUnits,
    pressureUnits,
    densityUnits,
    distanceUnits,
    temperatureUnits,
    getSelectionInformation,
    clearSelectionResultItems,
    addSelectionResultItem,
    setSelectionInformation,
    setSelectionResultItems,
    disableClearResults,
  };
});
