<template>
  <div>
    <label
      v-if="props.label"
      :for="props.id"
      class="block mb-2 text-sm font-medium text-gray-900"
      >{{ props.label }}</label
    >
    <textarea
      :id="props.id"
      :rows="props.rows"
      class="block p-2.5 w-full text-sm text-gray-900 bg-white border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
      :class="props.inputClass"
      :placeholder="props.placeholder"
      :value="modelValue"
      @input="emitInput($event)"
    ></textarea>
  </div>
</template>

<script setup>
const props = defineProps([
  "id",
  "label",
  "placeholder",
  "modelValue",
  "inputClass",
  "rows",
]);

const emits = defineEmits(["update:modelValue"]);

const emitInput = (evt) => {
  emits("update:modelValue", evt.target.value);
};
</script>

<style lang="scss" scoped></style>
