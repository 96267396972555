<template>
  <div>
    <label v-if="props.label" class="block text-sm font-medium text-gray-700">
      <T :keyName="props.label" />
    </label>
    <select @input="emitInput($event)" :value="modelValue" :class="selectClass">
      <option
        v-for="opt in options"
        :value="opt.value"
        :disabled="opt.disabled != undefined && opt.disabled"
      >
        <T :keyName="opt.text" />
      </option>
    </select>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
const props = defineProps([
  "modelValue",
  "color",
  "label",
  "options",
  "selectClass",
]);
const emits = defineEmits(["update:modelValue", "on-change"]);
const selectClass = computed(() => {
  return `${
    props.label ? "mt-1" : ""
  } block w-full border-gray-300 py-2 pl-3 pr-10 focus:border-${
    props.color
  }-500 focus:outline-none focus:ring-${props.color}-500 text-sm ${
    props.selectClass
  }`;
});
const emitInput = (evt) => {
  emits("update:modelValue", evt.target.value);
};
</script>

<style scoped></style>
