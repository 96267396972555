/* Creating a store for the user. */
import { ref, computed, reactive } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { fetchWrapper } from "../helpers/fetch-wrapper";
import { useAuthStore } from "./auth";

const baseUrl = `${import.meta.env.VITE_API_URL}/api/user`;

export const useUserStore = defineStore("user", () => {
  const authStore = useAuthStore();
  const { user } = storeToRefs(authStore);

  function register(user) {
    return fetchWrapper.post(`${baseUrl}/register`, user);
  }

  function updateUser(updateRequest) {
    return fetchWrapper
      .post(
        `${import.meta.env.VITE_API_URL}/api/user/update-user`,
        updateRequest
      )
      .then((response) => {
        user.value.company = updateRequest.company;
        user.value.title = updateRequest.title;
        user.value.firstName = updateRequest.firstName;
        user.value.lastName = updateRequest.lastName;
        user.value.preferences = updateRequest.userPreferences;
        user.value.mobileNumber = updateRequest.mobileNumber;
        user.value.currency = updateRequest.currency;
        localStorage.setItem("user", JSON.stringify(user.value));
      });
  }

  function updateOtherUser(updateRequest) {
    return fetchWrapper
      .post(
        `${import.meta.env.VITE_API_URL}/api/user/update-other-user`,
        updateRequest
      )
      .then((response) => {});
  }

  function getUserGroups() {
    return fetchWrapper.get(`${baseUrl}/get-all-user-groups`);
  }

  function getUsers() {
    return fetchWrapper.get(`${baseUrl}/get-all-users`);
  }

  function revokeUser(userId) {
    return fetchWrapper.post(`${baseUrl}/revoke-user`, { id: userId });
  }

  function approveUser(userId) {
    return fetchWrapper.post(`${baseUrl}/approve-user`, { id: userId });
  }

  function deleteUser(userId) {
    return fetchWrapper.post(`${baseUrl}/delete-user`, { id: userId });
  }

  function setUserGroup(userId, userGroupId) {
    return fetchWrapper.post(`${baseUrl}/set-user-group`, {
      id: userId,
      userGroupId,
    });
  }
  function updateUserGroup(userGroup) {
    return fetchWrapper.post(`${baseUrl}/update-user-group`, userGroup);
  }
  function addUserGroup(userGroup) {
    return fetchWrapper.post(`${baseUrl}/add-user-group`, userGroup);
  }

  function deleteUserGroup(userGroup) {
    return fetchWrapper.post(`${baseUrl}/delete-user-group`, userGroup);
  }

  return {
    register,
    updateUser,
    updateOtherUser,
    getUsers,
    getUserGroups,
    revokeUser,
    approveUser,
    setUserGroup,
    addUserGroup,
    updateUserGroup,
    deleteUserGroup,
    deleteUser,
  };
});
