import { createRouter, createWebHistory } from "vue-router";
import MainLayout from "../layouts/Main.vue";
import SelectionView from "../views/Selection.vue";
import HomeView from "../views/Home.vue";
import TechnicalView from "../views/Technical.vue";
import ProjectView from "../views/Project.vue";
import DashboardView from "../views/Dashboard.vue";
import LoginView from "../views/Login.vue";
import RegisterView from "../views/Register.vue";
import ForgotPasswordView from "../views/ForgotPassword.vue";
import ResetPasswordView from "../views/ResetPassword.vue";
import { useAuthStore } from "../stores/auth";
import { useGlobalStore } from "../stores/global";

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (to.name == "selection" && from.name == "technical") {
      return {
        el: "#selection-results",
        top: 100,
      };
    }
  },
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      redirect: "/selection",
      component: MainLayout,
      children: [
        {
          path: "home",
          component: HomeView,
        },
        {
          path: "selection",
          component: SelectionView,
          name: "selection",
        },
        {
          path: "technical/:referrer/:id",
          component: TechnicalView,
          name: "technical",
        },
        {
          path: "project",
          component: ProjectView,
        },
        {
          path: "dashboard",
          component: DashboardView,
        },
        {
          path: "login",
          alias: "verify-email",
          component: LoginView,
        },
        {
          path: "register",
          component: RegisterView,
        },
        {
          path: "forgot-password",
          component: ForgotPasswordView,
        },
        { path: "reset-password", component: ResetPasswordView },
      ],
    },
  ],
});

router.beforeEach(async (to, from) => {
  const authStore = useAuthStore();
  const globalStore = useGlobalStore();
  globalStore.previousRoute = from;
  const publicPages = [
    "/login",
    "/register",
    "/forgot-password",
    "/reset-password",
    "/verify-email",
  ];
  const noUserPages = [
    "/login",
    "/register",
    "/forgot-password",
    "/reset-password",
    "/verify-email",
  ];
  const authRequired = !publicPages.includes(to.path);
  const redirectToSelection =
    authStore.isAuthenticated && noUserPages.includes(to.path);

  if (authRequired && !authStore.isAuthenticated) {
    authStore.returnUrl = to.fullPath;
    return "/login";
  }
  if (redirectToSelection) {
    return "/selection";
  }
});

export default router;
